@media only screen and (min-width: 975px) {
  .animation-1 {
    --scaleIn-offset: .9;

    transform: scale(.9);

    position: relative;
    opacity: 0;
    animation: fadeIn .4s ease-in .2s forwards,
               scaleIn .3s ease-in forwards;
  }

  .animation-2 {
    --delay: 1s;
    --slideUp-offset: 10px;

    position: relative;
    top: var(--slideUp-offset);
    opacity: 0;
    animation: fadeIn .3s ease-in var(--delay) forwards, 
               slideUp .15s ease-in var(--delay) forwards; 
  }

    .animation-2-1 {
      --delay: 1.5s;
      --slideUp-offset: 5px;
    }

      .animation-2-1-1 {
        position: relative;
        top: var(--slideUp-offset);
        opacity: 0;
        animation: fadeIn .3s ease-in var(--delay) forwards,
                   slideUp .15s ease-in var(--delay) forwards;
      }

      .animation-2-1-2 {
        position: relative;
        top: var(--slideUp-offset);
        opacity: 0;
        animation: fadeIn .3s ease-in var(--delay) forwards,
                   slideUp .15s ease-in calc(var(--delay) + .1s) forwards;
      }

      .animation-2-1-3 {
        position: relative;
        top: var(--slideUp-offset);
        opacity: 0;
        animation: fadeIn .3s ease-in var(--delay) forwards, 
                   slideUp .15s ease-in calc(var(--delay) + .15s) forwards;
      }

  .animation-3 {
    --delay: 1.6s;
    --slideUp-offset: 15px;
  }

    .animation-3-1 {
      position: relative;
      top: var(--slideUp-offset);
      opacity: 0;
      animation: fadeIn .3s ease-in var(--delay) forwards, 
                 slideUp .3s ease-in var(--delay) forwards,
                 fadePhase 1s ease-in calc(var(--delay) + 2s) forwards;
    }

    .animation-3-2 {
      position: relative;
      top: var(--slideUp-offset);
      opacity: 0;
      animation: fadeIn .6s ease-in calc(var(--delay) + .2s) forwards, 
                 slideUp .3s ease-in calc(var(--delay) + .2s) forwards;
    }

      .animation-3-2-1 {
        opacity: 0;
        animation: fadeIn 1s ease-in calc(var(--delay) + .6s) forwards, 
                   fadePhase 1s ease-in calc(var(--delay) + 2s) forwards;
      }

    .animation-3-3 {
      position: relative;
      top: var(--slideUp-offset);
      opacity: 0;
      animation: fadeIn .3s ease-in calc(var(--delay) + .3s) forwards, 
                 slideUp .3s ease-in calc(var(--delay) + .3s) forwards;
    }

      .animation-3-3-1 {
        --scaleIn-offset: .7;

        opacity: 0;
        animation: fadeIn .3s ease-in calc(var(--delay) + 5.2s) forwards,
                   scaleIn .6s ease-in calc(var(--delay) + 5.2s) forwards,
                   blink .8s ease-out calc(var(--delay) + 6.3s) 6 alternate;
      }

  .animation-4 {
    --delay: 3.2s;
  }

    .animation-4-1 {
      --scaleIn-offset: 1.025;

      opacity: 0;
      animation: fadeIn 1s linear var(--delay) forwards, 
                 scaleIn .3s ease-in var(--delay) forwards;
    }

      .animation-4-1-1 {
        --slideDown-offset: -10px;

        position: relative;
        top: var(--slideDown-offset);
        opacity: 0;
        animation: fadeIn .6s ease-in calc(var(--delay) + .4s) forwards,
                   slideDown .3s ease-out calc(var(--delay) + .6s) forwards;
      }

      .animation-4-1-2 {
        --slideUp-offset: 10px;

        position: relative;
        top: var(--slideUp-offset);
        opacity: 0;
        animation: fadeIn .6s ease-in calc(var(--delay) + .4s) forwards,
                   slideUp .3s ease-out calc(var(--delay) + .6s) forwards;
      }

      .animation-4-1-3 {
        opacity: 0;
        animation: fadeIn .6s ease-in calc(var(--delay) + 1s) forwards;
      }

  .animation-5 {
    --delay: 5.5s;

    opacity: 0;
    animation: fadeIn .3s ease-in-out var(--delay) forwards;
  }

  .animation-6 {
    --delay: 9s;

    position: relative !important;
    opacity: 0;
    animation: fadeIn .3s ease-in-out var(--delay) forwards;
  }

    .animation-6-1 {
      --slideDown-offset: -20px;

      position: relative;
      top: var(--slideDown-offset);
      animation: fadeIn .3s ease-in var(--delay) forwards,
                 slideDown .3s ease-out calc(var(--delay) + .3s) forwards;
    }

    .animation-6-2 {
      opacity: 0;
      animation: fadeIn 1s ease-in calc(var(--delay) + 1.5s) forwards;
    }

    .animation-6-3 {
      opacity: 0;
      animation: fadeIn .3s ease-in calc(var(--delay) + 3s) forwards,
                 mouseNoise 1.5s ease-in-out calc(var(--delay) + 2.5s) forwards,
                 fadeOut .3s linear calc(var(--delay) + 4s) forwards;
    }

  .animation-7 {
    --delay: 10s;

    opacity: 0;
    animation: fadeIn .6s ease-in var(--delay) forwards;
  }
}

.animation--skip .animation {
  animation-delay: .4s !important;
  animation-duration: .8s !important;
  animation-fill-mode: forwards !important;
  animation-iteration-count: 1 !important;
}

.animation--skip .animation--hiddeable {
  display: none !important;
}