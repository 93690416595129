@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes fadePhase {
  from { opacity: 1; }
  to { opacity: .4; }
}

@keyframes slideUp {
  from { top: var(--slideUp-offset); }
  to { top: 0px; }
}

@keyframes slideDown {
  0% { top: var(--slideDown-offset); }
  50% { top: calc((var(--slideDown-offset) / 3) * -1); }
  75% { top: calc(var(--slideDown-offset) / 2); }
  100% { top: 0px; }
}

@keyframes slideLeft {
  from { left: 0 }
  to { left: var(--slideLeft-offset); }
}

@keyframes slideRight {
  from { right: 0 }
  to { right: var(--slideRight-offset); }
}

@keyframes scaleIn {
  0% { transform: scale(var(--scaleIn-offset)); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

@keyframes blink {
  from { opacity: 1; }
  to { opacity: .5; }
}

@keyframes zoomOut {
  from { transform: scale(1); }
  to { transform: scale(0.95); }
}

@keyframes zoomIn {
  from { transform: scale(1); }
  to { transform: scale(1.05); }
}

@keyframes mouseNoise {
  from { left: 0; top: 0; }
  to { left: 50%; top: 50%; }
}