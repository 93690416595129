@import "./stylesheets/keyframes.css";
/* Comment if you want to jump to final state  */
@import "./stylesheets/animation.css";

@import "./stylesheets/logo.css";
@import "./stylesheets/browser.css";
@import "./stylesheets/video.css";
@import "./stylesheets/popup.css";
@import "./stylesheets/split.css";
@import "./stylesheets/text.css";
@import "./stylesheets/mouse.css";
@import "./stylesheets/footer.css";
@import "./stylesheets/menu.css";

.container__focus {
  display: flex;
  flex-direction: column;
  padding: 10vh 0 20px 0;
  min-height: calc(100vh - (10vh + 20px));
}