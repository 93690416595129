.share-container {
  display: flex;
  min-height: calc(100vh - 30vh);
  margin: 0 0 30vh 0;
}

.share-logo {
  margin-bottom: 10vh;
}

.title {
  max-width: 50%;
  text-align: center;
  margin: 0 auto 3rem auto;
}

  @media only screen and (max-width: 975px) {
    .title {
      max-width: 100%;
    }
  }

.media {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

  @media only screen and (max-width: 975px) {
    .media {
      flex-direction: column-reverse;
    }
  }

.media__sidebar {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.settings {
  margin-top: 2rem; 
}

  .settings__item {
    display: flex;
    align-items: center;
    margin-bottom: .6rem;
    color: var(--color-gray-300);
    text-transform: uppercase;
    letter-spacing: .05em;
    transition: color .3s;
    filter: blur(1px);
    cursor: default;
  }

    .settings__item--enabled {
      color: var(--color-black);
      font-weight: 500;
      filter: blur(0);
    }

    .settings__item__icon {
      line-height: 0;
    }

    .settings__item__icon svg {
      width: 1.4em;
      height: 1.4em;
    }

    .settings__item__value {
      margin: 0 1rem;
      font-size: .9em;
    }

    .settings__item__state {
      margin-left: auto;
      font-weight: 400;
      font-size: .8em;
      font-family: var(--font-family-sans);
    }

.play {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

  .play__button {
    position: relative;
    text-transform: uppercase;
    letter-spacing: .15em;
    font-weight: 500;
    display: flex;
    align-items: center;
    font-size: .9em;
    padding: .5rem 1rem;
    border-radius: 9999px;
    color: var(--color-black);
    cursor: pointer;
    box-shadow: 0px 0px 35px var(--color-black);
    background-color: var(--color-white);
    transition: .1s ease-out transform;
  }

.thumbnail {
  position: relative;
  display: flex;
  justify-content: center;
  margin-left: 4rem;
  flex: 2;
  cursor: pointer;
  min-width: 300px;
  aspect-ratio: 16/9;
}

  @media only screen and (max-width: 975px) {
    .thumbnail {
      margin: 0 0 2rem 0;
    }
  }

  .thumbnail:hover .play__button {
    transform: scale(1.05);
  }

  .thumbnail img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    object-fit: contain;
  }

    .thumbnail__fx {
      position: absolute;
      mix-blend-mode: difference;
      bottom: 0;
      width: 100%;
      height: 100%;
    }

    .thumbnail__fx img {
      height: 100%;
      object-fit: cover;
    }

.end-bar {
  flex: .5;
}

.install-cta {
  font-size: .75em;
  margin-top: 2.5rem;
  line-height: 1.6;
  color: var(--color-gray-400);
  text-decoration: none;
  text-align: center;
}

  .install-cta svg {
    width: 1.5em;
    height: 1.5em;
    margin-right: .8em;
  }

  .install-cta span {
    text-transform: uppercase;
    font-weight: 600; 
    letter-spacing: .1em;
  }

  .install-cta__button {
    padding: .5rem 1rem;
    background: var(--color-black);
    color: var(--color-white);
    border-radius: 9999px;
    display: inline-flex;
    align-items: center;
  }

  .install-cta__text {
    margin-top: 1rem;
  }

    .install-cta__text b {
      font-weight: 500;
    }