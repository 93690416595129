.footer {
  display: none;
  flex-direction: column;
  flex: 1;
  margin-top: 2rem;
}

  .team-text {
    color: var(--color-gray-600);
    font-size: .85em;
    display: block;
    text-align: center;
  }

@media only screen and (min-width: 975px) {
  .footer {
    display: flex;
  }
}