.menu {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

  .menu__links {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }

  .menu__links a {
    color: var(--color-gray-600);
    font-size: .85em;
    font-weight: 500;
    text-decoration: none;
    text-align: center;
  }