#payhook-button > div {
  display: flex;
}

#payhook-button > div *:first-child {
  margin-right: .375rem;
}

#payhook-button input {
  position: relative;
  width: 100%;
  min-width: 0;
  font-family: inherit;
  margin: 0;
  padding: .375rem .75rem;
  line-height: inherit;
  color: inherit;
  outline: transparent solid 2px;
  outline-offset: 2px;
  appearance: none;
  font-size: 13px;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: inherit;
  background: inherit;
}

#payhook-button input:focus {
  z-index: 1;
  border-color: var(--color-pink-500);
  box-shadow: var(--color-pink-500) 0px 0px 0px 2px;
}

#payhook-button button {
  position: relative;
  display: inline-flex;
  width: auto;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  padding: .375rem .75rem;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  line-height: 1.2;
  border-width: 1px;
  border-color: transparent;
  border-radius: 6px;
  font-weight: 500;
  font-size: 13px;
  cursor: pointer;
  background: var(--color-black);
  color: var(--color-white);
}

#payhook-button button:focus {
  box-shadow: 0 0 0 3px var(--color-pink-500);
}