.popup-wrapper {
  position: relative;
}

  .popup-wrapper__inner {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    border: 1px solid var(--gray-300);
    z-index: 1;
  }

  .popup-wrapper__shadow {
    position: absolute; 
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    box-shadow: 0px 2px 29px -5px #0000001f;
    border-radius: 12px;
  }