.video {
  width: 400px;
}

  .video--active .video__media {
    filter: grayscale(0);
  }

  .video--fade {
    opacity: .4;
  }

  .video--freeze .video__media {
    filter: grayscale(1);
  }

  .video__media-wrapper {
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;
  }

  .video__title {
    margin-bottom: 1em;
    text-align: center;
    text-transform: uppercase;
    font-size: .85em;
    letter-spacing: .1em;
  }

  .video__media {
    position: absolute; 
    width: 102%;
    height: 102%;
    top: -1%;
    left: -1%;
    z-index: 1;
    border-radius: 8px;
    overflow: hidden;
  }

  .video__controls {
    position: absolute;
    width: calc(100% - 60px);
    left: 0;
    bottom: 25px;
    padding: 0 30px;
    z-index: 2;
  }

    .video__progress {
      position: relative;
      flex: 1;
      height: 5px;
      border-radius: 5px;
      overflow: hidden;
      background-color: var(--color-white);
    }

    .video__progress__area {
      position: absolute;
      width: 23%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: #FF0000;
    }

  .video__shadow {
    position: absolute; 
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    box-shadow: 0px 2px 29px -5px #000000a6;
  }