*, *::before, *::after {
  box-sizing: inherit;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

body {
  margin: 0;
  color: var(--color-black);
  font-size: 14px;
  font-family: var(--font-family-sans);

}

.container {
  --container-width: 800px;

  max-width: var(--container-width);
  margin: auto;
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 0 1rem;
  }
}