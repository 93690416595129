.product {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 3rem 0;
  justify-content: center;
}

  .product__title {
    display: none;
    margin: 0 auto;
    margin-bottom: 1rem;
    font-size: 1rem;
  }

  @media only screen and (min-width: 975px) {
    .product__title {
      display: block;
    }
  }

  .product__text {
    max-width: 50%;
    font-size: .9em;
    color: var(--color-gray-500);
    margin: 0 auto;
    display: block;
    text-align: center;
    line-height: 1.8;
  }