.checkout-email {
  margin-top: 2rem;
}

.upgrade-container {
  display: flex;
  min-height: calc(100vh - 80px);
  margin: 40px 0 40px 0;
}

.upgrade-logo {
  margin-bottom: 6rem;
}

@media only screen and (max-width: 768px) {
  .checkout {
    width: 100%;
  }
}

  .feature {
    display: flex;
    justify-content: space-between;
    margin-bottom: .375rem;
  }

    .feature > *:first-child {
      color: var(--color-gray-300);
    }

    .feature > *:last-child {
      text-align: right;
    }

    .feature--pro {
      color: var(--color-black);
      align-items: center;
    }

    .feature--pro > *:first-child {
      margin-right: 6rem;
    }

      .feature--pro > *:first-child img {
        width: 32px; 
      }

    .feature--pro > *:last-child {
      font-weight: 500;
    }

  .checkout-separator {
    width: 100%;
    height: .5rem;
  }

  .summary-item {
    display: flex;
    margin-bottom: .375rem; 
    justify-content: space-between;
  }

    .summary-item--total {
      font-weight: 600;
    }

    .summary-item--total span {
      color: var(--color-gray-300);
      text-decoration: line-through;
      font-weight: 500;
      margin-right: .25rem;
    }

  .email {
    margin-top: 4rem;
  }