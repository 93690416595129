:root {
  --black:    #1b1f23;
  --white:    #ffffff;

  --gray-50:  #fafbfc;
  --gray-100: #f6f8fa;
  --gray-200: #e1e4e8;
  --gray-300: #d1d5da;
  --gray-400: #959da5;
  --gray-500: #6a737d;
  --gray-600: #586069;
  --gray-700: #444d56;
  --gray-800: #2f363d;
  --gray-900: #24292e;

  --color-black:    var(--black);
  --color-white:    var(--white);

  --color-gray-50: var(--gray-50);
  --color-gray-100: var(--gray-100);
  --color-gray-200: var(--gray-200);
  --color-gray-300: var(--gray-300);
  --color-gray-400: var(--gray-400);
  --color-gray-500: var(--gray-500);
  --color-gray-600: var(--gray-600);
  --color-gray-700: var(--gray-700);
  --color-gray-800: var(--gray-800);
  --color-gray-900: var(--gray-900);

  --pink-100: #f8ecf9;
  --pink-200: #f1d8f3;
  --pink-300: #ebc5ed;
  --pink-400: #e4b1e7;
  --pink-500: #dd9ee1;
  --pink-600: #b17eb4;
  --pink-700: #855f87;
  --pink-800: #583f5a;
  --pink-900: #2c202d;

  --color-pink-100: var(--pink-100);
  --color-pink-200: var(--pink-200);
  --color-pink-300: var(--pink-300);
  --color-pink-400: var(--pink-400);
  --color-pink-500: var(--pink-500);
  --color-pink-600: var(--pink-600);
  --color-pink-700: var(--pink-700);
  --color-pink-800: var(--pink-800);
  --color-pink-900: var(--pink-900);
}