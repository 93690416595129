.split {
  display: flex; 
  align-items: center;
  justify-content: center;
  width: 100%;
}

  .split__item {
    position: relative;
    display: flex; 
    height: 100%;
    align-items: center;
    background-color: var(--color-white);
    padding: 10px;
  }

.split > *:first-child {
  margin-right: 35px;
}

.split > *:last-child {
  z-index: 2;
}