.logo {
  display: flex;
  justify-content: center;
  margin-top: 1vh;
  margin-bottom: 7vh;
  flex-shrink: 0;
}

  .logo img {
    width: auto;
    height: 20px;
  }

  .logo--pro img {
    height: 30px;
  }