.browser {
  width: 100%;
  border: 1px solid var(--color-gray-300);
  flex-direction: column;
  border-radius: 12px;
  display: none;
  flex-shrink: 0;
}
  
  /* Video width + Popup width + any margin */
  /* 400 + 500 + 35 + 40  */
  @media only screen and (min-width: 975px) {
    .browser {
      display: flex;
    }
  }

  .browser__header {
    padding: 12px 14px 6px 14px;
  }
  
  .browser__action-bar {
    display: flex;
  }

    .browser__action-bar .browser__action-bar__button {
      margin-right: 6px;
    }

    .browser__action-bar__button {
      width: 8px;
      height: 8px;
      border-radius: 10px;
      color: var(--color-gray-300);
      border: 1px solid var(--color-gray-300);
    }
  
  .browser__navigation-bar {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 15px;
  }

    .browser__navigation-bar__buttons {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }

      .browser__navigation-bar__buttons svg {
        width: 15px;
        height: 15px;
        color: var(--color-gray-300);
      }

      .browser__navigation-bar__buttons img:first-child {
        margin-right: 14px;
      }

    .browser__navigation-bar__address {
      display: flex;
      align-items: center;
      flex: 1;
      height: 22px;
      font-size: .8em;
      border: 1px solid var(--color-gray-300);
      border-radius: 6px;
      padding: 0 15px;
      color: var(--color-gray-500);
      margin: 0 14px;
    }

    .browser__navigation-bar__address__domain {
      font-weight: 500;
      color: black;
    }

  .browser__extension {
    width: 24px;
    height: 24px;
    border-radius: 10px;
    background-color: var(--color-pink-500);
  }

  .browser__body {
    padding-bottom: 25px;
    flex: 1; 
    display: flex;
    flex-direction: column;
  }

    .browser__content-states {
      position: relative;
      height: 100%;
      flex: 1;
    }

    .browser__content-state {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--color-white);
      border-radius: 12px;
    }

    .browser__content-state--1 {
      z-index: 1;  
    }

    .browser__content-state--2 {
      z-index: 2;  
    }

    .browser__content-state--3 {
      z-index: 3;  
    }